import React from 'react';
import img1 from "../assets/images/feature-1.png";
import img2 from "../assets/images/feature-2.png";
import img3 from "../assets/images/feature-3.png";
import img4 from "../assets/images/feature-4.png";
import img5 from "../assets/images/feature-5.png";
import img6 from "../assets/images/feature-6.png";
import DarkModeIcon from '@mui/icons-material/DarkMode';
import DomainVerificationIcon from '@mui/icons-material/DomainVerification';
import AssistantIcon from '@mui/icons-material/Assistant';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import BackupIcon from '@mui/icons-material/Backup';
const Feature = () => {
  return (
    <div>
        <section className="features-main" id='features'>
        <div className="container">
            <div className="heading-lines text-center">
                <h2><span>Features <div className="lines-box">
                            <div className="lines mb-1"></div>
                            <div className="lines"></div>
                        </div></span></h2>
            </div>

            <div className="row features-card-main">
                <div className="col-xs-12 col-md-4 mt-5">
                    <div className="row card-divide">
                    <div className="col-xs-3 col-sm-3 features-card-img d-flex justify-content-center align-items-center">
                        <BackupIcon  style={{ color: 'white', fontSize: '2.5rem' }}/>
                            {/* <img src={img1} className="img-fluid" alt=""/> */}
                        </div>
                        <div className="col-sm-9 card-text">
                            <p className="card-bg-text">01</p>
                            <h5>UNLIMITED  Storage</h5>
                            <p>At Webmail solution, you never have to worry about running out of space. Our unlimited storage feature ensures that you can store all your important emails.</p>
                        </div>
                    </div>
                </div>

                <div className="col-xs-12 col-md-4 mt-5">
                    <div className="row card-divide middle-card">
                    <div className="col-xs-3 col-sm-3 features-card-img d-flex justify-content-center align-items-center">
                        <DomainVerificationIcon  style={{ color: 'white', fontSize: '2.5rem' }}/>
                            {/* <img src={img2} className="img-fluid" alt=""/> */}
                        </div>
                        <div className="col-sm-9 card-text">
                            <p className="card-bg-text">02</p>
                            <h5>Custom Domain Support</h5>
                            <p>Create a professional identity with a custom email address that matches your brand or personal domain. </p>
                        </div>
                    </div>
                </div>

                <div className="col-xs-12 col-md-4 mt-5">
                    <div className="row card-divide">
                    <div className="col-xs-3 col-sm-3 features-card-img d-flex justify-content-center align-items-center">
                        <AssistantIcon  style={{ color: 'white', fontSize: '2.5rem' }}/>
                            {/* <img src={img3} className="img-fluid" alt=""/> */}
                        </div>
                        <div className="col-sm-9 card-text">
                            <p className="card-bg-text">05</p>
                            <h5> AI-generated quick responses to emails</h5>
                            <p>Save time with AI-generated suggested replies to streamline your email communication.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-md-4 mt-5">
                    <div className="row card-divide">
                    <div className="col-xs-3 col-sm-3 features-card-img d-flex justify-content-center align-items-center">
                            {/* <img src={img4} className="img-fluid" alt=""/> */}
                            <DarkModeIcon  style={{ color: 'white', fontSize: '2.5rem' }}/>
                        </div>
                        <div className="col-sm-9 card-text">
                            <p className="card-bg-text">04</p>
                            <h5>Dark Mode</h5>
                            <p>
                            Our Webmail solution provider now offers Dark Mode, a sleek and modern interface
                            Switch to a dark theme for the Webmail interface.</p>
                        </div>
                    </div>
                </div>

                <div className="col-xs-12 col-md-4 mt-5">
                    <div className="row card-divide middle-card">
                    <div className="col-xs-3 col-sm-3 features-card-img d-flex justify-content-center align-items-center">
                        <AutoFixNormalIcon style={{ color: 'white', fontSize: '2.5rem' }}/>
                            {/* <img src={img5} className="img-fluid" alt=""/> */}
                        </div>
                        <div className="col-sm-9 card-text">
                            <p className="card-bg-text">05</p>
                            <h5>Organizational Tools</h5>
                            <p>Managing your emails can be a breeze with our advanced organizational tools. Utilize labels, filters, categories, and folders to keep ensure you never miss an important message. </p>
                        </div>
                    </div>
                </div>

                <div className="col-xs-12 col-md-4 mt-5">
                    <div className="row card-divide">
                    <div className="col-xs-3 col-sm-3 features-card-img d-flex justify-content-center align-items-center">
                        <GTranslateIcon style={{ color: 'white', fontSize: '2.5rem' }}/>
                            {/* <img src={img6} className="img-fluid" alt=""/> */}
                        </div>
                        <div className="col-sm-9 card-text">
                            <p className="card-bg-text">06</p>
                            <h5>Multi-Language Support</h5>
                            <p>Communicate effectively in multiple languages with comprehensive multi-language support for emails.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  )
}

export default Feature;
