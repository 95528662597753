import React from 'react';
import about_img from "../assets/images/about-us-img.png";

const About = () => {
  return (
    <div>
      <section className="about-us-main" id='about'>
        <div className="container">
            <div className="heading-lines text-center">
                <h2><span>about-us <div className="lines-box">
                            <div className="lines mb-1"></div>
                            <div className="lines"></div>
                        </div></span></h2>
            </div>
            <div className="row about-us">
                <div className="col-xs-12 col-sm-12 col-md-4 about-img-main">
                    <img src={about_img} className="img-fluid" alt=""/>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-8 about-text-main">
                    <div className="about-us-text">
                        <p>At MailRead, we believe in the power of seamless communication. Our webmail service is designed to keep you connected, organized, and secure, no matter where you are. With a user-friendly interface, robust storage options, and cutting-edge security measures, MailRead ensures your email experience is smooth and worry-free. We offer advanced features such as AI-driven spam filtering, customizable inbox layouts, and seamless integration with your favorite tools, making it easier than ever to manage your digital correspondence.</p>
                        <p>Our mission at MailRead is to revolutionize the way you communicate by providing a reliable, efficient, and secure email service. We are committed to innovation, continually enhancing our platform to meet the evolving needs of our users. Whether you are a professional managing business communications or an individual keeping in touch with loved ones, MailRead is dedicated to delivering a superior email experience that empowers you to stay connected and productive. Join us at MailRead, and discover a new standard of email excellence.</p>

                        <a href="#" className="btn btn-primary">Learn more</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  )
}

export default About
