import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import img from "../assets/images/price-card-bottom.png";
import img1 from "../assets/images/price-list-icon.png";
import pop_img from "../assets/images/popup.png";
import star from "../assets/images/star.png";
import { Helmet } from 'react-helmet';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const Pricing = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [amount, setAmount] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isRegistered, setIsRegistered] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState('@mailread.org');
  const [loading, setLoading] = useState(false);
  const [duration, setDuration] = useState({ p3: '1', t3: 'M' });
  const [planDuration, setPlanDuration] = useState('');

  const login = "Login";

  // Storing email in the session...
  useEffect(() => {
    const storedEmail = sessionStorage.getItem('email');
    setEmail(storedEmail || '');
  }, []);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleDomainChange = (e) => {
    setSelectedDomain(e.target.value);
  };

  const handleClick = (event, index, price, p3, t3) => {
    setActiveIndex(index);
    setAmount(price);
    setDuration({ p3, t3 });
    setPlanDuration(duration);
  };

  const loginUser = async (email, password) => {
    try {
      const emailWithDomain = email + selectedDomain;

      const formData = new URLSearchParams();
      formData.append('email', emailWithDomain);
      formData.append('password', password);

      const response = await fetch(`https://mailread.org/api/ipn_register.php?Type=${login}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formData.toString(),
      });

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error logging in:', error);
      return { status: 'error' };
    }
  };

  const registerUser = async (email, password) => {
    try {
      const emailWithDomain = email + selectedDomain;

      const data = {
        email: emailWithDomain,
        password: password
      };

      const response = await fetch('https://mailread.org/api/ipn_register.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      const responseData = await response.json();
      if (responseData.error) {
        throw new Error(responseData.error || 'Failed to register user');
      }

      return responseData;
    } catch (error) {
      console.error('Error registering user:', error);
      throw error;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');

    if (!email || !password) {
      setError('Email and password are required');
      return;
    }

    if (password.length < 8) {
      setError('Password must be at least 8 characters long');
      return;
    }

    setLoading(true);
    try {
      const responseData = await loginUser(email, password);
      if (responseData.status === 'ok') {
        sessionStorage.setItem('email', email + selectedDomain);
        document.getElementById('amountpaypal').value = amount;
        document.getElementById('p3').value = duration.p3;
        document.getElementById('t3').value = duration.t3;
        document.getElementById('item_name').value = planDuration;
        document.getElementById('paypal-form').submit();
        setEmail('');
      } else if (responseData.status === 'notok') {
        // If login status is not ok, proceed with registration
        await handleRegisterSubmit(event);
      } else {
        setError('An error occurred. Please try again.');
      }
    } catch (error) {
      setError('An error occurred while checking the email.');
    } finally {
      setLoading(false);
    }
  };

  const handleRegisterSubmit = async (event) => {
    event.preventDefault();
    setError('');

    if (!email || !password) {
      setError('Email and password are required');
      return;
    }

    if (password.length < 8) {
      setError('Password must be at least 8 characters long');
      return;
    }

    setLoading(true);
    try {
      const responseData = await registerUser(email, password);
      if (responseData.email) {
        sessionStorage.setItem('email', email + selectedDomain);
        document.getElementById('amountpaypal').value = amount;
        document.getElementById('p3').value = duration.p3;
        document.getElementById('t3').value = duration.t3;
        document.getElementById('item_name').value = planDuration;
        document.getElementById('paypal-form').submit();
      } else {
        setError('Registration failed. Please try again.');
      }
    } catch (error) {
      setError('An error occurred while registering.');
    } finally {
      setLoading(false);
    }
  };

  const handleAddToCartClick = (index, price, p3, t3,duration) => {
    setActiveIndex(index);
    setAmount(price);
    setDuration({ p3, t3 });
    setPlanDuration(duration);
    const modal = new window.bootstrap.Modal(document.getElementById('GFG'));
    modal.show();
  };

  const handleCancelClick = () => {
    const modal = new window.bootstrap.Modal(document.getElementById('GFG'));
    modal.hide();
    window.location.reload();
  };

  const handleModalClose = () => {
    setActiveIndex(null);
    setAmount('');
    setError('');
    setIsRegistered(false);
  };

  useEffect(() => {
    const modalElement = document.getElementById('GFG');
    if (modalElement) {
      modalElement.addEventListener('hidden.bs.modal', handleModalClose);
    }

    return () => {
      if (modalElement) {
        modalElement.removeEventListener('hidden.bs.modal', handleModalClose);
      }
    };
  }, []);

  return (
    <div>
      <section className="price-main" id='pricing'>
        <div className="container">
          <div className="heading-lines text-center">
            <h2><span>Pricing <div className="lines-box">
              <div className="lines mb-1"></div>
              <div className="lines"></div>
            </div></span></h2>
          </div>
          <div className="row price-card-main">
            {[
              { plan: 'Starter Plan', price: '9', time:'1 month', features: [ 'Custom Domain', 'Webmail Access', 'Email Forwarding','Mobile App Access', 'UNLIMITED Storage'] },
              { plan: 'Professional Plan', price: '19', time:'6 month', best:'best', features: ['Includes STARTER Plan ', ' Email Templates', 'Priority Customer Support', 'Email Alias', 'UNLIMITED Storage'] },
              { plan: 'Enterprise Plan', price: '29', time:'12 month', features: ['Includes PROFESSIONAL Plan ',  'File Sharing','Advanced Email Filtering',"Dedicated Account Manager",'UNLIMITED Storage'] }
            ].map((plan, index) => (
              <div className="col-xs-12 col-sm-12 col-md-4" key={index}>
                <div className="price-card">
               
                  <img src={img} className="img-fluid price-bottom" alt="" />
                  
                  <h3 className='plan-heading'>{plan.plan}</h3>
                  <p className="price-heading">MailRead is your all-in-one online Webmail solution <br /></p>
                  <div className="plan-price">
                    <h2>${plan.price}</h2>
                    <p> {plan.time}</p>
                  </div>
                  {plan.features.map((feature, featureIndex) => (
                    <div className="row price-list-main" key={featureIndex}>
                      <div className="col-xs-2 col-sm-2 col-md-2 price-img">
                      <CheckCircleIcon style={{ color: '#26658c', fontSize: '20px' }} />
                      </div>
                      <div className="col-xs-10 col-sm-10 col-md-10">
                        <p>{feature}</p>
                      </div>
                    </div>
                  ))}
                  <div className="price-buy-btn">
                    <Button
                      variant='contained'
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#GFG"
                      onClick={() => handleAddToCartClick(index, plan.price, '1', 'M', plan.plan)}
                    >
                      Buy Now
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <div className="modal fade" id="GFG">
           <div className="modal-dialog modal-lg">
             <div className="modal-content">
               <div className="modal-header">
                 <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleCancelClick}></button>
               </div>
               <div className="modal-body">
               <div class="heading-lines text-center">
                    <h2><span>select plan <div class="lines-box">
                                <div class="lines mb-1"></div>
                                <div class="lines"></div>
                            </div></span></h2>
                </div>
                 <div className="row">
                   {[
                     { plan: 'Starter Plan', price: '9', duration: '1 Month', index: 0, p3: '1', t3: 'M' },
                     { plan: 'Professional Plan', price: '19', duration: '6 Months', index: 1, p3: '6', t3: 'M' },
                     { plan: 'Enterprise Plan', price: '29', duration: '12 Months', index: 2, p3: '1', t3: 'Y' }
                   ].map((popupPlan, popupIndex) => (
                     <div className="col-md-4" key={popupIndex}>
                       <div className={`popup-card ${activeIndex === popupPlan.index ? 'active' : ''}`}>
                       <h3>{popupPlan.plan}</h3>
                         <img width="150px" src={pop_img} alt="" />
                         <h4>${popupPlan.price}</h4>
                        
                         <p>{popupPlan.duration}</p>
                         <button
                           type="button"
                           className="btn popup-btn select-btn"
                           onClick={(event) => handleClick(event, popupPlan.index, popupPlan.price, popupPlan.p3, popupPlan.t3,popupPlan.duration)}
                         >
                           Select Plan
                         </button>
                       </div>
                     </div>
                   ))}
                 </div>
                 <div className="container-fluid account-container mt-4">
                   <h2>Account Information</h2>
                   {!isRegistered ? (
                     <form className="account-form" onSubmit={handleSubmit}>
                       <div className="input-group username-div">
                         <label htmlFor="email">Email Address</label>
                         <div className="input-group">
                            <select
                             className="form-control"
                             value={selectedDomain}
                             onChange={handleDomainChange}
                             id="domain"
                             disabled
                           >
                             <option value="@mailread.org">@mailread.org</option>
                           </select>
                           <input
                             type="text"
                             value={email}
                             id="email"
                             placeholder="Email Address"
                             onChange={handleEmailChange}
                             className='rounded'
                             required
                           />
                          
                         </div>
                       </div>
                       <div className="input-group">
                         <label htmlFor="password">Password</label>
                         <input
                           type="password"
                           id="password"
                           placeholder="Password"
                           value={password}
                           onChange={handlePasswordChange}
                           className='rounded'
                           required
                         />
                         <h6>This will be used for logging into your account.</h6>
                       </div>
                       {error && <div className="error-message">{error}</div>}
                       <div className="float-end d-flex popup-footer">
                         <button type="button" className="btn btn-secondary" onClick={handleCancelClick} data-bs-dismiss="modal">Cancel</button>
                         <button type="submit" className="btn cancel-btn" id="cancel" disabled={loading}>
                           {loading ? 'Processing...' : 'Pay Now'}
                         </button>
                       </div>
                     </form>
                   ) : (
                     <form className='account-form' onSubmit={handleRegisterSubmit}>
                       <div className="input-group username-div">
                         <label htmlFor="registerEmail">Email Address</label>
                         <div className="input-group">
                         <select
                             className="form-control"
                             value={selectedDomain}
                             id="domain"
                             onChange={handleDomainChange}
                             disabled
                           >
                             <option value="@mailread.org">@mailread.org</option>
                           </select>
                           <input
                             type="text"
                             value={email}
                             id="registerEmail"
                             placeholder="Email Address"
                             onChange={handleEmailChange}
                             className='rounded me-2'
                             required
                           />
                           
                         </div>
                       </div>
                       <div className="input-group">
                         <label htmlFor="registerPassword">Password</label>
                         <input
                           type="password"
                           id="registerPassword"
                           placeholder="Password"
                           value={password}
                           onChange={handlePasswordChange}
                           className='rounded'
                           required
                         />
                         <h6>This will be used for logging into your account.</h6>
                       </div>
                       {error && <div className="error-message">{error}</div>}
                       <div className="float-end d-flex popup-footer">
                         <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={handleCancelClick}>Cancel</button>
                         <button type="submit" className="btn cancel-btn" id="cancel" disabled={loading}>
                           {loading ? 'Processing...' : 'Register & Pay Now'}
                         </button>
                       </div>
                     </form>
                   )}
                 </div>
                 <form
                   id='paypal-form'
                   action="https://www.sandbox.paypal.com/cgi-bin/webscr"
                   method="post"
                   style={{ display: 'none' }}
                 >
                   <input type="hidden" name="business" value="sb-e47uc430331287@business.example.com" />
                   <input type="hidden" name="cmd" value="_xclick-subscriptions" />
                   <input type="hidden" name="a3" id='amountpaypal' value={amount} />
                   <input type="hidden" name="p3" id='p3' value={duration.p3} />
                   <input type="hidden" name="t3" id='t3' value={duration.t3} />
                   <input type="hidden" name="src" value="1" />
                   <input type="hidden" name="sra" value="0" />
                   <input type="hidden" name="item_name" id='item_name' value={planDuration} />
                   <input type="hidden" name="item_number" value={email + selectedDomain} />
                   <input type="hidden" name="no_note" value="1" />
                   <input type="hidden" name="currency_code" value="USD" />
                   <input type="hidden" name="return" value="https://mailread.org/Dashboard" />
                   <input type="hidden" name="cancel_return" value="https://mailread.org/Dashboard" />
                   <input type="hidden" name="notify_url" value="https://mailread.org/api/ipn.php" />
                 </form>
               </div>
             </div>
           </div>
         </div>
    </div>
  );
};

export default Pricing;
