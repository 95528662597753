import React from 'react';
import img1 from "../assets/images/multiple-account.png";
import img2 from "../assets/images/read-email.png";
import img3 from "../assets/images/undo-mail.png";
import img4 from "../assets/images/notification(2).png";
import img5 from "../assets/images/domain.png";
import img6 from "../assets/images/management.png";
import img7 from "../assets/images/dark.png";
import img8 from "../assets/images/read-email.png";
import img9 from "../assets/images/calendar.png";
import img10 from "../assets/images/benifit-6.png";

const Benifits = () => {
  return (
    <div>
      <section className="benifits-main">
        <div className="container">
            <div className="heading-lines text-center">
                <h2><span>Benefits with Premium plain <div className="lines-box">
                            <div className="lines mb-1"></div>
                            <div className="lines"></div>
                        </div></span></h2>
            </div>
            <div className="row benifit-card-main">
                <div className="col-xs-12 col-sm-12 col-md-4 mt-5">
                    <div className="gx-3 benifit-card text-center">
                        <img src={img1} className="img-fluid" alt=""/>
                        <p> Email Aliases: Ability to create and use multiple email addresses linked to a single account.</p>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-4 mt-5">
                    <div className="gx-3 benifit-card text-center">
                        <img src={img2} className="img-fluid" alt=""/>
                        <p>Smart Reply/Suggested Replies: AI-generated quick responses to emails.</p>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-4 mt-5">
                    <div className="gx-3 benifit-card text-center">
                        <img src={img3} className="img-fluid" alt=""/>
                        <p>Undo Send: Option to recall an email shortly after sending it.</p>
                    </div>
                </div>
            
                <div className="col-xs-12 col-sm-12 col-md-4 mt-5">
                    <div className="gx-3 benifit-card text-center">
                        <img src={img4} className="img-fluid" alt=""/>
                        <p>Priority Notifications: Customizable notifications for emails from specific contacts or categories.</p>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-4 mt-5">
                    <div className="gx-3 benifit-card text-center">
                        <img src={img5} className="img-fluid" alt=""/>
                        <p>Custom Domain Support: Ability to use a custom email domain.</p>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-4 mt-5">
                    <div className="gx-3 benifit-card text-center">
                        <img src={img6} className="img-fluid" alt=""/>
                        <p>Task Management: Integrated task lists and to-do lists.</p>
                    </div>
            </div>
            {/* <div className="col-xs-12 col-sm-12 col-md-4 mt-5">
                    <div className="gx-3 benifit-card text-center">
                        <img src={img7} className="img-fluid" alt=""/>
                        <p>Dark Mode: Option to switch to a dark theme for the email interface.</p>
                    </div>
                </div> */}
                {/* <div className="col-xs-12 col-sm-12 col-md-4 mt-5">  
                    <div className="gx-3 benifit-card text-center">
                        <img src={img8} className="img-fluid" alt=""/>
                        <p>Read Receipts: Notifications when an email has been opened by the recipient.</p>
                    </div>
                </div> */}
                {/* <div className="col-xs-12 col-sm-12 col-md-4 mt-5">
                    <div className="gx-3 benifit-card text-center">
                        <img src={img9} className="img-fluid" alt=""/>
                        <p>Email Scheduling: Ability to compose emails and schedule them to be sent at a later time.</p>
                    </div>
                </div> */}
        </div>
        </div>
    </section>
    </div>
  )
}

export default Benifits;
