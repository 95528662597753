import React from 'react';
import Logo from "../assets/images/mail-logo.png";
import style from "../css/style.css";
import { Link } from 'react-router-dom';
const Header = () => {
  return (
    <div>
      <header className="header-main">
        <div className="upper-header container">
            <Link to="/Login" className="btn btn-sign-up">Sign-In</Link>
        </div>
        <nav className="navbar navbar-expand-lg navbar-light">
            <div className="container">
                <a className="navbar-brand p-0" href="#"><img src={Logo}alt=""/></a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                    <ul className="navbar-nav header-links">
                        <li className="nav-item">
                            <Link className="nav-link" aria-current="page" to="/">Home</Link>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#features">Features</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#about">About Us</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#how">How To Use</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#pricing">Pricing</a>
                        </li>
                         {/* <li className="nav-item">
                            <a className="nav-link" href="#faq">FAQ'S</a>
                        </li>  */}
                    </ul>
                </div>
            </div>
        </nav>
    </header>
    </div>
  )
}

export default Header;
