import React from 'react';
import img from "../assets/images/choose-us.png";
import img1 from "../assets/images/choose-1.png";
import img2 from "../assets/images/choose-2.png";
import img3 from "../assets/images/choose-3.png";
import img4 from "../assets/images/choose-4.png";

const Why = () => {
  return (
    <div>
         <section className="choose-us-main" id='why'>
        <div className="container">
            <div className="heading-lines text-center">
                <h2><span>Why Choose Us <div className="lines-box">
                            <div className="lines mb-1"></div>
                            <div className="lines"></div>
                        </div></span></h2>
            </div>
            <div className="row choose-us">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="choose-us-card">
                        <h5>Accessibility Features</h5>
                        <p>Our Webmail Service provider is dedicated to creating an inclusive digital environment for everyone. Our enhanced accessibility features are designed to support users with disabilities, ensuring that everyone can communicate, collaborate, and stay connected with ease.</p>
                    </div>
                    <div className="choose-us-card">
                        <h5>Personalized Email Signatures</h5>
                        <p>With MailRead ,Create and manage multiple email signatures tailored to different contexts, projects, or recipients. Ensure consistent branding across all your communications with customizable signatures that reflect your professional image.</p>
                    </div>
                    <div className="choose-us-card">
                        <h5>Vacation Responders</h5>
                        <p>Our Vacation Responders feature ensures you maintain professional communication with your contacts even when you're not available. Whether you’re on vacation, out of the office, or simply unavailable, our automatic replies keep your correspondents informed.</p>
                    </div>
                    <div className="choose-us-card">
                        <h5>Large Attachment Support</h5>
                        <p>Cloud storage provides virtually limitless capacity, enabling users to upload and share files of any size without worrying about email attachment limits.</p>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 choose-us-img">
                    <img src={img} className="img-fluid" alt=""/>
                    <div className="choose-card-icons">
                        <div className="choose-us-lins"></div>
                        <div className="choose-icon">
                            <img src={img1} alt=""/>
                        </div>
                        <div className="choose-us-lins"></div>
                        <div className="choose-icon">
                            <img src={img2} alt=""/>
                        </div>
                        <div className="choose-us-lins"></div>
                        <div className="choose-icon">
                            <img src={img3} alt=""/>
                        </div>
                        <div className="choose-us-lins"></div>
                        <div className="choose-icon">
                            <img src={img4} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  )
}

export default Why;
