import React from 'react';
import img1 from "../assets/images/how-use.png";
import img2 from "../assets/images/make-profile.png";
import img3 from "../assets/images/set-price.png";

const How = () => {
  return (
    <div>
      <section className="how-to-use-main" id='how'>
        <div className="container">
        <div>
        <div className="heading-lines text-center">
                        <h2><span className='mb-4'>How to Use <div className="lines-box">
                                    <div className="lines mb-1"></div>
                                    <div className="lines"></div>
                                </div></span></h2>
                    </div>
                    </div>
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-4 how-use-main-img">
                    {/* <div className="heading-lines">
                        <h2><span>How to Use <div className="lines-box">
                                    <div className="lines mb-1"></div>
                                    <div className="lines"></div>
                                </div></span></h2>
                    </div> */}
                    <img src={img1} className=" how-use-img" alt=""/>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-8">
                    <div className="how-use-card">
                        <div className="row how-card-1">
                            <div className="col-xs-3 col-sm-5 text-end">
                                <img src="img/sign-up.png" className="mt-2 img-fluid use-img" alt=""/>
                            </div>
                            <div className="col-xs-9 col-sm-7">
                                <h5> Choose Your Perfect Plan:</h5>
                                <p> Dive into our range of tailored plans designed to meet every need, from individual users to large enterprises. Explore the unique features each plan offers and select the one that perfectly aligns with your email usage and storage requirements.</p>
                            </div>
                        </div>

                        <div className="row how-card-2">
                            <div className="col-xs-9 col-sm-10 how-card-2-text">
                                <h5>Secure and Easy Payment:</h5>
                                <p>With just a few clicks, securely complete your payment through our streamlined and user-friendly checkout process. We support multiple payment methods to ensure a hassle-free experience. Once your payment is confirmed, you’re all set to access the full power of MailRead.</p>
                            </div>
                            <div className="col-xs-3 col-sm-2 text-end how-card-2-img">
                                <img src={img2} className="img-fluid" alt=""/>
                            </div>
                        </div>

                        <div className="row how-card-3">
                            <div className="col-xs-3 col-sm-5 text-end">
                                <img src={img3} className="img-fluid use-img" alt=""/>
                            </div>
                            <div className="col-xs-9 col-sm-7">
                                <h5>Enjoy All the Amazing Features:</h5>
                                <p>If you ever have questions or need assistance, our dedicated customer support team is
                                    here to help. Don't hesitate to reach out for guidance.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  )
}

export default How;
