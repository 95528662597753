import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { Link, useNavigate,useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Logo from "../assets/images/mail-logo.png";
import pop_img from "../assets/images/popup.png";
//import box from "../assets/box.png";
import style from "../css/style.css";
import dashboard from "../css/dashboard.css";
//import Paypal from './Paypal';
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import "../css/style.css";
import axios from 'axios';
import { Helmet } from 'react-helmet';
const Dashboard = () => {
const navigate = useNavigate();
const basicAuth = 'QVIxY0dmdkxPUzM4LTFYSWdhdENIRVhNcnlqYVQxdHZZMEwwWXdXT0dvMkhLT1JFQzRTWmE4NlUwaXdoNVBJc0QxSFFLTm41N1pQUGRpLUc6RVB5RGxlQzJvX3ZPQXNnUnVvMncwZ0c1dHplVEVOQmhIc3MwNWFxbVZMT0Z5OFJzN1JEM2xqTGRpd043WE1NX3hxcjVJNEdZN3FxVHU4Ul8';
const location = useLocation();
const [email, setEmail] = useState('');
const [isLoggedIn, setIsLoggedIn] = useState(false);
const [checkout, setCheckout] = useState(false);
const [data, setData] = useState(null);
const [activeIndex, setActiveIndex] = useState(null);
const [isLoading, setIsLoading] = useState(true);
const [responseData, setResponseData] = useState([]);
const [amount, setAmount] = useState('');
const [api_key, setApiKey] = useState('');
const [subscription_id, setSubscriptionId] = useState('');
const [currentDate, setCurrentDate] = useState('');
const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        const now = new Date();
        const formattedDate = now.toLocaleString('en-US', {
            timeZone: 'UTC',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        });
        setCurrentDate(formattedDate);
    }, []);

const handleCheckout = () => {
    if (!checkout) {
        setCheckout(true);
    }
};

useEffect(() => {
    
    const message = sessionStorage.getItem('successMessage');
    if (message) {
      setSuccessMessage(message);
      sessionStorage.removeItem('successMessage');
    }
  }, []);

//getting payerId..
// useEffect(() => {
//   const getQueryParam = (name) => {
//     const params = new URLSearchParams(window.location.search);
//     return params.get(name);
//   };
//   const tokens = getQueryParam('token');
//   if(tokens) {
//   const message = document.getElementById("success-paypal");
//   message.innerHTML = "<strong className='success'>Payment Successfully done</strong>";
//  // console.log('id:', payerId);
//   }
// }, []);



// useEffect(() => {
//   const searchParams = new URLSearchParams(location.search);
//   const token = searchParams.get('token');
//   const apiKey = '963b1ca860cc8942940651303d1477ba29b30e609d0406e42ccdb84cf2e48e2f';

  
//   if (token && apiKey) {
//       // Make a POST request to your backend with token and api_key
//       postDataToBackend(apiKey, token);
//   }
// }, [location.search]); 


 
  

      const handleClick = (event,index) => {
        setActiveIndex(index);
        const customattributes = event.target.getAttribute('price');
        //document.getElementById('amountpaypal').value = customattributes;
        document.getElementById('cancel').removeAttribute('disabled');
        setAmount(customattributes);
       //console.log("the values are", customattributes);
      }

      const generateRandomNumber = () => {
        return Math.floor(Math.random() * 100) + 1; 
      };
     // const apiKeys = '963b1ca860cc8942940651303d1477ba29b30e609d0406e42ccdb84cf2e48e2f';
    
     const apiKey = sessionStorage.getItem('email');
     const emails = sessionStorage.getItem('apiKey');
     //console.log("The api key",apiKey);
     //console.log("The email is", emails);






      //making post request to the backend to fetch subscription data..
      useEffect(() => {
        // Function to make the API request
        const fetchData = () => {
          axios.post(`https://mailread.org/api/ipn.php?user=${apiKey}`)
            .then(response => {
              setResponseData(response.data);
              setIsLoading(false);
             // console.log(response.data);
            })
            .catch(error => {
              setIsLoading(false);
              //console.error("Error fetching data", error);
            });
        };
    
        // Fetch data immediately on mount
        fetchData();
    
        // Set up an interval to fetch data periodically
        const intervalId = setInterval(fetchData, 5000); // Fetch every 3 seconds
    
        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
      }, [apiKey]);
    
      
  


       const handleSignOut = ()=> {
        sessionStorage.removeItem('email');
        navigate('/');
        //console.log('Logged out successfully');
       }



       
    //    const handleSignOut = () => {
       
    //     const email = sessionStorage.getItem('email');
    //     const sessionKey = sessionStorage.getItem('apiKey');
      
       
    //     const credentials = btoa(`${email}:${sessionKey}`);
      
    //     const myHeaders = new Headers();
    //     myHeaders.append("Authorization", `Basic ${credentials}`);
    //     myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
      
    //     const requestOptions = {
    //       method: "POST",
    //       headers: myHeaders,
    //       redirect: "follow"
    //     };
      
    //     fetch("https://166.88.198.78/admin/logout", requestOptions)
    //       .then((response) => {
    //         sessionStorage.removeItem('email');
    //         navigate('/Login');
    //       })
    //       .catch((error) => console.error(error));
    //   };
      
      //const Email = sessionStorage.getItem('email');
      //console.log("The email is ", Email);
      useEffect (() => {
        const Emails = sessionStorage.getItem('email');
        //console.log(Emails);
        if(!Emails) {
            navigate("/Login");
            setEmail(Emails);
        }else {
            setIsLoggedIn(true);
            console.log("The email is ", Emails);
        }
      },[]);


      const date = new Date();
      const currentDateUTC = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
      const formattedDate = currentDateUTC.toISOString().split('T')[0]; // Extracting only the 
      //console.log(formattedDate); 

     
     const firstId = responseData.length > 0 ? responseData[0].id : null;
     var username = sessionStorage.getItem('email');

     

     //manually suspended the subscription....
    //  const handleSuspend = () => {
    //     if (firstId) {
    //         const confirm = window.confirm("Are you sure to deactivate your account");
    //         if (confirm) {
    //             // First API call to suspend subscription
    //             const suspendSubscription = axios.post(
    //                 `https://api-m.sandbox.paypal.com/v1/billing/subscriptions/${firstId}/suspend`,
    //                 {},
    //                 {
    //                     headers: {
    //                         'Authorization': 'Basic QVIxY0dmdkxPUzM4LTFYSWdhdENIRVhNcnlqYVQxdHZZMEwwWXdXT0dvMkhLT1JFQzRTWmE4NlUwaXdoNVBJc0QxSFFLTm41N1pQUGRpLUc6RVB5RGxlQzJvX3ZPQXNnUnVvMncwZ0c1dHplVEVOQmhIc3MwNWFxbVZMT0Z5OFJzN1JEM2xqTGRpd043WE1NX3hxcjVJNEdZN3FxVHU4Ul8',
    //                         'Content-Type': 'application/json'
    //                     }
    //                 }
    //             );
    
    //             // Second API call to remove user email..
    //             const removeUserEmail = axios.post(
    //                 'https://box.derstaclout.monster/admin/mail/users/remove',
    //                 {
    //                     email: username 
    //                 },
    //                 {
    //                     headers: {
    //                         'Authorization': 'Basic bWVAZGVyc3RhY2xvdXQubW9uc3RlcjpqMW1mT2R2ZUVmTWNUOEkxSGJuMQ==', 
    //                         'Content-Type': 'application/x-www-form-urlencoded'
    //                     }
    //                 }
    //             );
    
              
    //             Promise.all([suspendSubscription, removeUserEmail])
    //                 .then(responses => {
    //                     console.log("Response from first API:", responses[0]);
    //                     console.log("Response from second API:", responses[1]);
    //                     setTimeout(()=> {
    //                         alert("successfully deactivated your account");
    //                     },1500)
    //                 })
    //                 .catch(error => {
    //                     console.error("Error while making API calls:", error);
    //                 });
    //         } else {
    //             console.log('User canceled suspension');
    //         }
    //     } else {
    //         console.error('No ID available to suspend');
    //     }
    // };
    
    
    const time = responseData.length > 0 ? new Date(responseData[0].date) : null;
    console.log(time);

    // remove the email from the admin when plan expires...
    // useEffect(() => {
    //     if (time) {
    //         const today = new Date();
    //         console.log(today);
    //         const expireDate = new Date(time);
    //         console.log(expireDate);
    //         // Check if today's date matches the expiration date
    //         if (today.getFullYear() === expireDate.getFullYear() &&
    //             today.getMonth() === expireDate.getMonth() &&
    //             today.getDate() === expireDate.getDate()) {
    
    //             // Call the remove email API  
    //             axios.post(
    //                 'https://box.derstaclout.monster/admin/mail/users/remove',
    //                 {
    //                     email: username 
    //                 },
    //                 {
    //                     headers: {
    //                         'Authorization': 'Basic bWVAZGVyc3RhY2xvdXQubW9uc3RlcjpqMW1mT2R2ZUVmTWNUOEkxSGJuMQ==', 
    //                         'Content-Type': 'application/x-www-form-urlencoded'
    //                     }
    //                 }
    //             )
    //             .then(mailResponse => {
    //                 console.log(mailResponse);
    //             })
    //             .catch(mailErr => {
    //                 console.log(mailErr);
    //             });
    //         }
    //     }
    // }, [time]); 


    useEffect(() => {
        if (time) {
            const today = new Date();
            // console.log(today);
            const expireDate = new Date(time);
            // console.log(expireDate);
            // Check if today's date matches the expiration date
            if (today.getFullYear() === expireDate.getFullYear() &&
                today.getMonth() === expireDate.getMonth() &&
                today.getDate() === expireDate.getDate()) {
    
                // Make parallel requests
                Promise.all([
                    // Comment out the first API call
                    // axios.post(
                    //     `https://api-m.sandbox.paypal.com/v1/billing/subscriptions/${firstId}/suspend`,
                    //     {},
                    //     {
                    //         headers: {
                    //             'Authorization': 'Basic QVIxY0dmdkxPUzM4LTFYSWdhdENIRVhNcnlqYVQxdHZZMEwwWXdXT0dvMkhLT1JFQzRTWmE4NlUwaXdoNVBJc0QxSFFLTm41N1pQUGRpLUc6RVB5RGxlQzJvX3ZPQXNnUnVvMncwZ0c1dHplVEVOQmhIc3MwNWFxbVZMT0Z5OFJzN1JEM2xqTGRpd043WE1NX3hxcjVJNEdZN3FxVHU4Ul8',
                    //             'Content-Type': 'application/json'
                    //         }
                    //     }
                    // ),
                    axios.post(
                        'https://box.derstaclout.monster/admin/mail/users/remove',
                        {
                            email: username 
                        },
                        {
                            headers: {
                                'Authorization': 'Basic bWVAZGVyc3RhY2xvdXQubW9uc3RlcjpqMW1mT2R2ZUVmTWNUOEkxSGJuMQ==', 
                                'Content-Type': 'application/x-www-form-urlencoded'
                            }
                        }
                    )
                ])
                .then(([, mailResponse]) => {
                    // console.log(paypalResponse); // No longer needed
                    console.log(mailResponse);
                })
                .catch(error => {
                    console.log(error);
                });
            }
        }
    }, [time]);
    
    
      
      
  return (
   <div>
       {isLoggedIn && (
        <>
       
       
    <section className='dashboard-contain'>
     <h1>{successMessage}</h1>
     <div className="container-fluid">
  
        <div className="row flex-nowrap">
            <div className="desh-main col-auto col-md-3 col-xl-2 px-sm-2 px-1">
                <div className="d-flex flex-column align-items-md-center align-items-sm-start pt-2 text-white min-vh-100">
                    <Link to="/" className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                        <span className="fs-5 d-none d-sm-inline"><img src={Logo} className="" width="30px" alt=""/></span>
                    </Link>
                    <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
                        <li>
                            <Link to="/Dashboard" className="nav-link px-0 align-middle">
                                <i className="fa fa-home" aria-hidden="true"></i> <span className="d-none d-sm-inline">Dashboard</span> </Link>
                        </li>
                        <li>
                            <Link to="/Invoices" className="nav-link px-0 align-middle">
                                <i className="fa fa-file-text" aria-hidden="true"></i><span className=" ms-2 d-none d-sm-inline">Invoices</span></Link>
                        </li>
                        <li>
                            <a href="https://mailread.org/mail/"
                               target='_blank'
                               className="nav-link px-0 align-middle">
                                <i className="fa fa-file-text" aria-hidden="true"></i><span className=" ms-2 d-none d-sm-inline">Webmail</span></a>
                        </li>
                       
                    </ul>
                    <hr/>
                    <div className="dropdown pb-4 signout-dashbaord">
                     <button className='btn btn-primary'>
                         <i className="fa fa-sign-out cursor-pointer " aria-hidden="true" onClick={handleSignOut}>Signout</i>    
                    </button>   
                
                    </div>
                </div>
            </div>
            <div className="col py-3">
                <div id="submenu1" className=" dashboard">
                    <div className=" dashboard-main">
                        <div className="">
                            <h4 className=" fw-bold">Dashboard</h4>
                            <div id='success-paypal'></div>
                            <p>Here is your Email account. Let’s surf securely!</p>
                        </div>
                        <div className="">
                            <div className="header-card">
                                <div className="">
                                    <h2>0</h2>
                                </div>
                                <div className="Orders-btn">
                                    <h6>Unpaid Orders</h6>
                                    <Button variant='contained' type="button" className="dash-btn" id='new'>
                                    <Link to="/Invoices" className='link'>View Detail</Link>   
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="table-main">
                    {isLoading ? (
                         <div className="skeleton-loader">
                         <Skeleton height={40} count={5} />
                     </div>
                ) : (
                    Array.isArray(responseData) && responseData.length > 0 ? (
                        <table className="">
                            <thead>
                              <tr>
                                <th scope="col">Username</th>
                                <th scope="col">Plan</th>
                                <th scope="col">Price</th>
                                <th scope="col">Expired At</th>
                                {/* <th scope="col">Action</th> */}
                               
                              </tr>
                            </thead>
                            <tbody>
                            {responseData.map((item, index) => (
                       <tr key={index}>
                        <td className='sub-price'>{apiKey}</td>
                        <td className='sub-price'>Monthly</td>
                        <td className='sub-price'>
                            {`$${item.amount}`}
                        </td>
                        <td className='sub-price'>
                            {item.date && new Date(item.date).toLocaleDateString('en-US', { timeZone: 'UTC' })}
                        </td>
                        <td>
                        <form action="https://www.sandbox.paypal.com/cgi-bin/webscr" method="post">
                            <input type="hidden" name="business" value="sb-e47uc430331287@business.example.com"/>
                            <input type="hidden" name="cmd" value="_xclick-subscriptions"/>
                            <input type="hidden" name="a3" id='amountpaypal' />
                            <input type="hidden" name="p3" value="1"/> 
                            <input type="hidden" name="t3" value="M"/> 
                            <input type="hidden" name="src" value="1"/> 
                            <input type="hidden" name="sra" value="0"/> 
                            <input type="hidden" name="item_name" value="Monthly Subscription"/>
                            <input type="hidden" name="item_number" value={apiKey}/>
                            <input type="hidden" name="no_note" value="1"></input>
                            <input type="hidden" name="currency_code" value="USD"/>           
                            <input type="hidden" name="return" value="https://mailread.org/Dashboard"/>
                            <input type="hidden" name="cancel_return" value="https://mailread.org/Dashboard"/>
                            <input type="hidden" name="notify_url" value="https://mailread.org/api/ipn.php"></input>   
                  
                    {/* {item.status === 'CANCELLED' || item.status === 'SUSPENDED' ||  new Date(item.date) > new Date() ? (
                            <Button type="submit" variant='contained' id='renew'  disabled>Pay Now</Button>
                        ) : (
                            <Button type="submit" variant='contained' id="new" >Pay Now</Button>
                        )} */}

                        {/* {!(item.status === 'CANCELLED' || item.status === 'SUSPENDED' || new Date(item.date) > new Date(new Date().setDate(new Date().getDate() + 7))) && (
                            <Button type="submit" variant="contained" id="new">RENEW</Button>
                        )} */}


                    </form>
                    </td>
                    {/* <td>
                    {item.status !== 'SUSPENDED' && (
                        <button onClick={handleSuspend}>Cancel</button>
                    )}
                    </td> */}
                        
                    </tr>
                ))}
                    </tbody>
                          </table>
                        ) : (
                            <div className='no-data'>You don't have a Email account. Please purchase a plan.</div>
                         )
                         )}
                        </div>
                    
                      <Button type="button"
                      variant="contained"
                      color="primary"
                    className="pop_up"
                    data-bs-toggle="modal"
                    data-bs-target="#GFG"> 
                    Buy Now
                    </Button> 
                    <div className="dash-footer">
                     <p>© {currentYear}. Mail Read. All Rights reserved.</p>
                    </div>
                    
                </div>

            </div>
        </div>
        
    </div>
    <div className="w-80 p-3"> 
    {/* Buy Now Pop up  */}
		<div className="modal fade" id="GFG"> 
			<div className="modal-dialog modal-lg"> 
				<div className="modal-content"> 
					<div className="modal-header"> 
						{/* <img src={Logo} width="30px" /> */}
						<button type="button"className="btn-close"data-bs-dismiss="modal"> 
						</button> 
					</div> 
					<div className="modal-body"> 
                    <div class="heading-lines text-center">
                    <h2><span>select plan <div class="lines-box">
                                <div class="lines mb-1"></div>
                                <div class="lines"></div>
                            </div></span></h2>
                    </div>
                            <div class="row">
                                <div className="col-md-4" >
                                <div className={`popup-card ${activeIndex === 0 ? 'active' : ''}`}>
                                       <h3>Starter Plan</h3>
                                        <img width="150px" src={pop_img} alt=""/>
                                        <h4 price='13.77'>$9</h4>
                                        <p>1 month</p>
                                        <button type="btn" className="btn popup-btn" price='14.00' onClick={(event) => handleClick(event,0)}>Select Plan</button>

                                    </div>
                                </div>
                                <div className="col-md-4">
                                <div className={`popup-card ${activeIndex === 1 ? 'active' : ''}`}>
                                <h3>Professional Plan</h3>
                                        <img width="150px" src={pop_img} alt=""/>
                                        <h4 price="13.77">$12</h4>
                                       
                                        <p>6 Months</p>
                                        <button type="btn" className="btn popup-btn" price='13.77' onClick={(event) => handleClick(event,1)}>Select Plan</button>

                                    </div>
                                </div>
                                <div className="col-md-4" >
                                <div className={`popup-card ${activeIndex === 2 ? 'active' : ''}`}>
                                <h3>Enterprise Plan</h3>
                                        <img width="150px" src={pop_img} alt=""/>
                                        <h4 > $29</h4>
                                       
                                        <p>12 Months</p>
                                        <button type="button" className="btn popup-btn" price='13.77' onClick={(event) => handleClick(event,2)}>Select Plan</button>
                                    </div>
                                </div>
                            </div>
                            <div className=" float-end d-flex popup-footer">
                        <button type="button"  className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button> 
                      <form action="https://www.sandbox.paypal.com/cgi-bin/webscr" method="post">
                        <input type="hidden" name="business" value="sb-e47uc430331287@business.example.com"/>
                        <input type="hidden" name="cmd" value="_xclick-subscriptions"/>
                        <input type="hidden" name="a3" id='amountpaypal' value={amount}/>
                        <input type="hidden" name="p3" value="1"/> 
                        <input type="hidden" name="t3" value="M"/> 
                        <input type="hidden" name="src" value="1"/> 
                        <input type="hidden" name="sra" value="0"/> 
                        <input type="hidden" name="item_name" value="Monthly Subscription"/>
                        <input type="hidden" name="item_number" value={apiKey}/>
                        <input type="hidden" name="no_note" value="1"></input>
                        <input type="hidden" name="currency_code" value="USD"/>           
                        <input type="hidden" name="return" value="https://mailread.org/Dashboard"/>
                        <input type="hidden" name="cancel_return" value="https://mailread.org/Dashboard"/>
                        <input type="hidden" name="notify_url" value="https://mailread.org/api/ipn.php"></input>   
                        <button type="submit" className="btn cancel-btn" id='cancel' disabled>Pay Now</button>
                    </form>
                              </div>
                                </div> 
                            </div> 
                        </div> 
                    </div> 
                    
                </div> 
</section>
 </>
   )}
   </div>
 
  )
}

export default Dashboard;
