import React from 'react';
import banner_img from "../assets/images/banner-img.png";
import style from "../css/style.css";
const Banner = () => {
  return (
    <div>
      <section className="banner-main">
        <div className="container banner">
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-5 banner-text">
                    <h1>Unlock the Value of <br/> Your Emails</h1>
                    <p>MailRead is your all-in-one online Webmail solution, accessible from any device with internet. It allows you to send, receive, and organize emails seamlessly, while offering generous storage, advanced security features like spam filtering and two-factor authentication, and seamless integration with other tools like calendars and cloud storage. Effortlessly manage your communications, stay secure, and connect with ease—anytime, anywhere.</p>
                    <a href="#pricing" className="btn btn-primary">get your mail</a>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-7 banner-img">
                    <img src={banner_img} className="img-fluid" alt=""/>
                </div>
            </div>
        </div>
    </section>
    </div>
  )
}

export default Banner;
